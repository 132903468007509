import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
import { EmpresasService } from 'src/app/services/empresas.service';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
declare var $: any;

@Component({
  selector: 'app-slidebar',
  templateUrl: './slidebar.component.html',
  styleUrls: ['./slidebar.component.css'],
})
export class SlidebarComponent implements OnInit, OnDestroy {
  user;
  arrPermisos = [] as any;
  permiso = {} as any;
  aprobado = false;
  bitacora = [] as any;
  fechas = [] as any;
  sidebarBitActive = false;

  idCompany: string;
  idProject: string;

  routerSubscriber: Subscription;
  userSubscriber: Subscription;
  permisosSubscriber: Subscription;
  getEmpresaSubscriber: Subscription;
  roleuserSubscriber: Subscription;
  getBitacoraSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private empresaService: EmpresasService,
    private router: Router,
    private generalService: GeneralService
  ) {
    const url = router.parseUrl(router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
  }

  ngOnInit() {
    this.getBitacora();
    this.generalService.getBit(this.idCompany, this.idProject);
    this.userSubscriber = this.authService.userData$.subscribe(user => {
      this.user = user;
      this.roleuserSubscriber = this.authService
        .roleUser(this.user.uid)
        .subscribe((res: any) => {
          if (res[0].userType === 'Administrador') {
            this.aprobado = true;
            // this.getPermisos();
          } else {
            this.getAcceso();
          }
        });
    });
  }

  getAcceso() {
    this.getEmpresaSubscriber = this.empresaService
      .getProjectActual(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        this.aprobado = false;
        res.users.forEach(element => {
          if (this.user.uid === element.uid) {
            this.aprobado = true;
          }
        });
        if (!this.aprobado) {
          this.router.navigate(['dashboard']);
        } else {
          // this.getPermisos();
        }
      });
  }

  // getPermisos() {
  //   this.permisosSubscriber = this.authService
  //     .getPremissions()
  //     .subscribe((res: any) => {
  //       this.arrPermisos = Object.assign([], res);
  //       this.generarPermisos();
  //     });
  // }

  // generarPermisos() {
  //   for (let index = 0; index < this.arrPermisos.length; index++) {
  //     const element = this.arrPermisos[index];
  //     this.permiso[element.page] = false;
  //     element.users.forEach(elementUsers => {
  //       if (this.user.email === elementUsers) {
  //         this.permiso[element.page] = true;
  //       }
  //     });
  //   }
  // }

  toggle() {
    $('#sidebar').toggleClass('active');
    $('.overlay').toggleClass('active');
  }

  toggleBit() {
    if (this.sidebarBitActive) {
      document.getElementById('sidebarBit').classList.remove('sidebarBit-open');
      document.getElementById('main').classList.remove('main-shift');
      document.getElementById('arrow').classList.add('fa-angle-left');
      document.getElementById('arrow').classList.remove('fa-angle-right');
      // $('.overlayBit').toggleClass('active');
      this.sidebarBitActive = !this.sidebarBitActive;
    } else {
      document.getElementById('sidebarBit').classList.add('sidebarBit-open');
      document.getElementById('main').classList.add('main-shift');
      document.getElementById('arrow').classList.remove('fa-angle-left');
      document.getElementById('arrow').classList.add('fa-angle-right');
      // $('.overlayBit').toggleClass('active');
      this.sidebarBitActive = !this.sidebarBitActive;
    }
  }

  openNav() {
    document.getElementById('sidebarBit').classList.add('sidebarBit-open');
    document.getElementById('main').classList.add('main-shift');
    $('.overlayBit').toggleClass('active');
  }

  closeNav() {
    document.getElementById('sidebarBit').classList.remove('sidebarBit-open');
    document.getElementById('main').classList.remove('main-shift');
    $('.overlayBit').toggleClass('active');
  }

  getBitacora() {
    this.getBitacoraSubscription = this.generalService
      .getBitacora(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        if (res) {
          this.bitacora = res?.bitacora.reverse();
          this.bitacora.forEach(element => {
            const fecha = new Date(element.fecha.seconds * 1000);
            this.fechas.push(fecha);
          });
        }
      });
  }

  updateBitacora(mensaje: string) {
    const obj = {
      fecha: new Date(),
      mensaje: mensaje,
      user: this.user,
    };
    this.bitacora.push(obj);
    this.generalService.updateBitacora(
      this.idCompany,
      this.idProject,
      this.bitacora
    );
  }

  ngOnDestroy() {
    this.userSubscriber.unsubscribe();
    // if (this.permisosSubscriber) {
    //   this.permisosSubscriber.unsubscribe();
    // }
    if (this.getEmpresaSubscriber) {
      this.getEmpresaSubscriber.unsubscribe();
    }
    if (this.roleuserSubscriber) {
      this.roleuserSubscriber.unsubscribe();
    }
    this.getBitacoraSubscription.unsubscribe();
  }
}
