import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProvidersService } from 'src/app/services/providers.service';
import { Subscription } from 'rxjs';
import { RequestService } from 'src/app/services/request.service';
import { NgxXml2jsonService } from 'ngx-xml2json';

import Notiflix from 'notiflix-angular';

import { Router } from '@angular/router';
import { BudgetService } from 'src/app/services/budget.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { AuthService } from 'src/app/services/auth.service';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { Solicitud, Valores } from 'src/app/models/solicitud';
import { ProjectService } from 'src/app/services/project.service';
import { Period } from 'src/app/models/period';
import { PurchaseOrder } from 'src/app/models/purchaseOrder';
import { GeneralService } from 'src/app/services/general.service';

declare var $: any;

@Component({
  selector: 'app-sol-gral',
  templateUrl: './sol-gral.component.html',
  styleUrls: ['./sol-gral.component.css'],
})
export class SolGralComponent implements OnInit {
  providers = [] as any;
  provider = {} as any;
  proveedorSeleccionado = '';

  countRequest: number;
  idCompany: string;
  idProject: string;
  company = {} as any;
  project = {} as any;

  arrCountRequest = [] as any;
  order = {} as PurchaseOrder;

  request: Solicitud;
  budget = [] as any;
  fileError = [] as any;
  arrXML = [] as any;
  arrXMLGeneral = [] as any;
  xml = {} as any;
  nameXML: string;
  public rfcReceptor = '';
  user: string;
  periods = [] as any;
  periodoActual = {} as Period;
  binding: any;
  changePeriod = false;
  arrayFechas = [] as any;
  filmadoras = [];
  changeFilmadora = false;
  ordenesEncontradas = [] as any;
  changeCountRequest = false;
  arrImportador = [] as any;
  arrOrders = [] as any;
  indexOrdenSeleccionada: number = undefined;
  indexComprometidoSeleccionado: number = undefined;
  ordenSeleccionada = {} as any;
  comprometidosSeleccionados = [] as any;
  comprometidoSeleccionado = {} as any;
  fechaComprobante: string;
  acumulado = 0;
  countDealMemo = {} as any;
  existeOrden: boolean;
  editCuenta = false;
  acumuladoTemporal = 0;
  disponible = 0;
  acumuladoCuenta = 0;
  disponibleCuenta = 0;
  acumuladoCuentaIva = 0;
  disponibleCuentaIva = 0;
  disponibleDelComprometido = 0;
  addValue = {} as any;
  arrAcumulados = [] as any;
  bitacora = [] as any;
  isEdit: any;
  comprobante = {
    partidaPres: 'Selecciona una partida',
  } as Valores;
  sumas = {
    importe: 0,
    iva: 0,
    total: 0,
  } as any;
  dataFile = {} as any;

  // Subscriptions
  getProviderSubscription: Subscription;
  subscribeCountRequest: Subscription;
  getCompanySubscription: Subscription;
  subscriberGetProject: Subscription;
  subscriberGetUser: Subscription;
  subscriberBudget: Subscription;
  subscriberGetPurchaseOrder: Subscription;
  subscribePeriods: Subscription;
  subscribeGetCFDI: Subscription;
  subscribeCountDealMemo: Subscription;
  getPOSubscription: Subscription;
  findOrderSubscription: Subscription;
  findOrderIvaSubscription: Subscription;

  constructor(
    private providersService: ProvidersService,
    private requestService: RequestService,
    private router: Router,
    private ngxXml2jsonService: NgxXml2jsonService,
    private budgetService: BudgetService,
    private dealMemoService: DealMemoService,
    private empresasService: EmpresasService,
    private authService: AuthService,
    private projectService: ProjectService,
    private generalService: GeneralService
  ) {
    this.request = {
      countRequest: undefined,
      concepto: '',
      cfdi: {
        path: '',
        url: '',
      },
      fechaSol: undefined,
      user: '',
      tipo: 'Directa',
      moneda: '',
      TC: 0,
      periodo: '',
      etapa: '',
      filmadora: {
        name: '',
        rfc: '',
      },
      aprobadoPor: '',
      valores: [
        // {
        //   concepto: '',
        //   fechaComp: null,
        //   folio: '',
        //   importe: 0,
        //   iva: 0,
        //   retIva: 0,
        //   retIsr: 0,
        //   total: 0,
        //   nombreProveedor: '',
        //   partidaPres: '',
        //   partidaPresIva: '',
        //   fechaInicio: undefined,
        //   fechaFin: undefined,
        //   rfc: '',
        // },
      ],
      deducible: 'deducible',
    };
  }

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.isEdit = url.root.children.primary.segments[4].path;
    // console.log(this.isEdit);
    this.getCount();
    this.getPeriods();
    this.getCompany();
    this.getBudget();
    this.getProvider();
    this.getXML();
    this.getUser();
  }

  getCompany() {
    this.getCompanySubscription = this.empresasService
      .getCompanyActual(this.idCompany)
      .subscribe((res: any) => {
        const objFilmadora = {
          name: res.name,
          rfc: res.rfc,
        };
        this.filmadoras.push(objFilmadora);
        this.company = res;
        this.rfcReceptor = res.rfc.toUpperCase();
        this.getProject();
      });
  }

  getProject() {
    this.subscriberGetProject = this.empresasService
      .getProjectSpecific(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        this.project = res;
        this.request.moneda = res.monedaLocal;
        this.request.TC = res.tipoCambioGral;

        if (res.filmadoras) {
          this.filmadoras = this.filmadoras.concat(res.filmadoras);
        }
        this.request.filmadora = this.filmadoras[0];
      });
  }

  selectFilmadora() {
    this.changeFilmadora = false;
  }

  getUser() {
    this.subscriberGetUser = this.authService.userData$.subscribe(res => {
      this.user = res.email;
    });
  }

  getPeriods() {
    this.subscribePeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periods = Object.assign([], res);
        this.filtrarperiodo();
      });
  }
  filtrarperiodo() {
    const fecha = new Date();
    for (let index = 0; index < this.periods.length; index++) {
      const element = this.periods[index];
      const inicio = new Date(element.fechaInicio + 'T00:00:00');
      const fin = new Date(element.fechaFin + 'T23:59:59');
      if (inicio <= fecha && fin >= fecha) {
        this.periodoActual = element;
        this.binding = this.periodoActual;
        this.request.periodo = element.nombrePeriodo;
        this.request.etapa = element.etapa;
        // this.request.valores[0].fechaInicio = inicio;
        // this.request.valores[0].fechaFin = fin;
        break;
      }
    }
  }
  cambiarPeriodoActual() {
    this.periodoActual = this.binding;
    this.request.periodo = this.binding.nombrePeriodo;
    this.request.valores[0].fechaInicio = this.binding.fechaInicio;
    this.request.valores[0].fechaFin = this.binding.fechaFin;
    this.request.etapa = this.binding.etapa;
    this.changePeriod = false;
  }
  getCount() {
    this.subscribeCountRequest = this.requestService
      .getCountRequest(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        if (res === undefined) {
          this.countRequest = 1;
          this.request.countRequest = this.countRequest;
        } else {
          this.arrCountRequest = res.countRequest;
          this.countRequest = this.arrCountRequest.length + 1;
          for (let index = 0; index < res.countRequest.length; index++) {
            const element = res.countRequest[index];
            if (element !== index + 1) {
              this.countRequest = res.countRequest[index - 1] + 1;
              break;
            }
            this.request.countRequest = this.countRequest;
          }
        }
      });
  }

  countRequestManual() {
    if (!this.subscribeCountRequest.closed) {
      this.subscribeCountRequest.unsubscribe();
    }
    for (let index = 0; index < this.arrCountRequest.length; index++) {
      const element = this.arrCountRequest[index];
      this.request.countRequest = this.countRequest;
      if (element === this.countRequest) {
        Notiflix.Notify.Failure(
          `La solicitud numero ${this.countRequest} ya existe`
        );
        this.getCount();
        break;
      }
    }
  }

  getProvider() {
    this.getProviderSubscription = this.providersService
      .getProvider()
      .subscribe(res => {
        this.providers = Object.assign([], res);
      });
  }

  getCountOrder() {
    this.subscribeCountDealMemo = this.dealMemoService
      .getCountDealMemo(this.idCompany, this.idProject)
      .subscribe(res => {
        const contador: any = res;
        if (res === undefined) {
          this.countDealMemo.countDealMemo = 1;
        } else {
          this.countDealMemo.countDealMemo = contador.countDealMemo + 1;
        }
        this.order.orderCounter = this.countDealMemo.countDealMemo;
      });
  }

  seleccionOrden(item, i) {
    this.indexComprometidoSeleccionado = undefined;
    this.comprometidoSeleccionado = {};
    this.ordenSeleccionada = item;
    this.indexOrdenSeleccionada = i;
    this.arrayFechas = [] as any;

    this.arrAcumulados = [] as any;

    item.comprometidos.forEach(elementComprometidos => {
      let suma = 0;
      this.ordenSeleccionada.solicitudes.forEach(elementSolicitudes => {
        elementSolicitudes.valores.forEach(elementValores => {
          if (elementComprometidos.partidaPres === elementValores.partidaPres) {
            suma += elementValores.total;
          }
        });
      });
      this.arrAcumulados.push(suma);
    });

    this.request.moneda = item.moneda;
    this.request.TC = item.TC;

    item.comprometidos.forEach(element => {
      if (element.etapa) {
        const dateInicio = new Date(element.fechaInicio.seconds * 1000);
        const dateFin = new Date(element.fechaFin.seconds * 1000);

        this.arrayFechas.push({
          fechaInicio: dateInicio.toLocaleDateString(),
          fechaFin: dateFin.toLocaleDateString(),
        });
      }
    });
    this.comprometidosSeleccionados = item.comprometidos;
    console.log('Orden seleccionada', this.ordenSeleccionada);
  }

  findOrders() {
    this.findOrderSubscription = this.dealMemoService
      .findOrders(this.idCompany, this.idProject, this.comprobante.partidaPres)
      .subscribe(res => {
        console.log('Ordenes encontradas', res);
        this.acumuladoTemporal = 0;
        this.disponible = 0;

        if (res.length > 0) {
          // Si hay ordenes
          res.forEach(elementOrder => {
            // Recorro las ordenes
            elementOrder.comprometidos.forEach(elementComprometidos => {
              // Recorro los comprometidos
              if (
                elementComprometidos.partidaPres ===
                this.comprobante.partidaPres
              ) {
                let comprometido = 0;
                if (this.project.monedaLocal === this.request.moneda) {
                  comprometido =
                    elementComprometidos.importe *
                    elementComprometidos.cantidad;
                  this.acumuladoTemporal += comprometido;
                } else {
                  comprometido =
                    elementComprometidos.importe *
                    this.request.TC *
                    elementComprometidos.cantidad;
                  this.acumuladoTemporal += comprometido;
                }
              }
            });
          });
          const obj = this.budget.find(
            element => element.CodigoAgrupador === this.comprobante.partidaPres
          );

          let suma = 0;
          this.request.valores.forEach(element => {
            suma += element.importe;
          });

          this.disponible =
            obj.Importe_Estimado - this.acumuladoTemporal - suma;
        } else {
          const obj = this.budget.find(
            element => element.CodigoAgrupador === this.comprobante.partidaPres
          );
          let suma = 0;
          this.request.valores.forEach(element => {
            suma += element.importe;
          });
          if (this.project.monedaLocal === this.request.moneda) {
            this.disponible = obj.Importe_Estimado - suma;
          } else {
            this.disponible = obj.Importe_Estimado * this.request.TC - suma;
          }
        }

        this.acumuladoCuenta = this.acumuladoTemporal;
        this.disponibleCuenta = this.disponible;

        console.log('AcumuladoTemporal: ' + this.acumuladoTemporal);
        console.log('Disponible Presupuesto: ' + this.disponible);

        this.findOrderSubscription.unsubscribe();
      });
  }

  selectComprometido(item, i) {
    console.log('Comprometido seleccionado', item);
    this.comprometidoSeleccionado = item;
    this.indexComprometidoSeleccionado = i;

    let importe = item.importe;
    console.log('Acumulado', this.arrAcumulados);
    this.disponibleDelComprometido = importe - this.arrAcumulados[i];
    console.log('Disponible del comprometido', this.disponibleDelComprometido);
    this.comprobante.partidaPres = item.partidaPres;
    this.comprobante.partidaPresIva = item.partidaPresIva;
  }

  selectComprometidoOLD(item, i) {
    console.log(item);
    this.comprometidoSeleccionado = item;
    this.indexComprometidoSeleccionado = i;

    this.findOrderSubscription = this.dealMemoService
      .findOrders(this.idCompany, this.idProject, item.partidaPres)
      .subscribe(res => {
        this.acumuladoTemporal = 0;
        this.disponible = 0;
        if (res.length > 0) {
          res.forEach(elementOrder => {
            elementOrder.comprometidos.forEach(elementComprometidos => {
              if (elementComprometidos.partidaPres === item.partidaPres) {
                const comprometido =
                  elementComprometidos.importe * elementComprometidos.cantidad;
                this.acumuladoTemporal += comprometido;
              }
            });
          });
          const obj = this.budget.find(
            element => element.CodigoAgrupador === item.partidaPres
          );

          this.disponible = obj.Importe_Estimado - this.acumuladoTemporal;
        }

        console.log('AcumuladoTemporal: ' + this.acumuladoTemporal);
        console.log('Disponible Presupuesto: ' + this.disponible);

        console.log(this.request);

        let sumaImportes = this.request.valores
          .filter(
            ({ partidaPres }) => partidaPres === this.comprobante.partidaPres
          )
          .reduce(
            (acumulador, valorActual) => acumulador + valorActual.importe,
            0
          );
        console.log(sumaImportes);
        if (
          sumaImportes <=
          item.importe * item.cantidad -
            this.arrAcumulados[this.indexComprometidoSeleccionado]
        ) {
          console.log(
            item.importe * item.cantidad -
              this.arrAcumulados[this.indexComprometidoSeleccionado]
          );
          console.log('ok');
          this.acumulado =
            this.arrAcumulados[this.indexComprometidoSeleccionado];
          console.log(this.arrAcumulados);
          console.log(this.acumulado);
          this.comprobante.partidaPres = item.partidaPres;
          this.comprobante.partidaPresIva = item.partidaPresIva;
          this.request.aprobadoPor =
            this.arrOrders[this.indexOrdenSeleccionada].aprobadoPor;
          // this.addComprobante();
        } else {
          Notiflix.Notify.Failure('El importe excede el valor disponible');
          Notiflix.Confirm.Show(
            'Excede valor disponible',
            '¿Deseas modificar la cantidad o el valor de la orden de compra?',
            'Si',
            'No',
            () => {
              $('#modalEditCtas').modal('show');
            }
          );
          this.indexComprometidoSeleccionado = undefined;
        }

        this.findOrderSubscription.unsubscribe();
      });
  }

  validarDisponible() {
    this.addValue.cantidad;
    let newValues = {} as any;

    newValues.cantidad =
      this.addValue.cantidad + this.comprometidoSeleccionado.cantidad;
    newValues.importe =
      this.addValue.importe + this.comprometidoSeleccionado.importe;

    const newValue = newValues.cantidad * newValues.importe;

    const total =
      this.comprometidoSeleccionado.cantidad *
      this.comprometidoSeleccionado.importe;

    const totalAComprometer = newValue - total;

    if (totalAComprometer <= this.disponible) {
      this.comprometidoSeleccionado.cantidad = newValues.cantidad;
      this.comprometidoSeleccionado.importe = newValues.importe;
      this.disponible -= totalAComprometer;
    } else {
      Notiflix.Notify.Failure(
        'La nueva cantidad e importe excede el disponible del presupuesto'
      );
    }
  }

  guardarModalCuentas() {
    $('#modalEditCtas').modal('show');
  }

  getBudget() {
    this.subscriberBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          const arrBudget = this.budgetService.ctasFilter2(budget); // Esta función desglosa todo el presupuesto
          //console.log(budget); // este es el array que contiene hijos (children)
          let arrFilter = arrBudget.filter(
            obj => obj.CodigoAgrupador.split('-').length === 3
          );
          this.budget = arrFilter; // Este es el array que mostramos en la lista
          // console.log(this.budget);
        }
      });
  }

  getXML() {
    this.subscribeGetCFDI = this.projectService
      .getXML(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        if (res !== undefined) {
          this.arrImportador = res.arrXML;
        }
      });
  }

  onFileChange(ev) {
    for (let index = 0; index < ev.target.files.length; index++) {
      const archivo = ev.target.files[index];
      if (archivo.type === 'text/xml') {
        const lector = new FileReader();
        lector.onload = e => {
          this.xmlToJson(e, archivo);
        };
        lector.readAsText(archivo);
      } else {
        Notiflix.Notify.Failure(
          `El archivo ${archivo.name} no es un archivo XML`
        );
      }
    }
    // (<any>document.getElementById('inputXML')).value = '';
  }
  xmlToJson(lector, archivo) {
    const res = lector.target.result;
    const parser = new DOMParser();
    const xmlData = parser.parseFromString(res, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(xmlData);
    this.xml = this.generalService.assignDataCFDI(obj);
    console.log(this.xml);
    // this.changeSelectProvider();
    this.validarXML();
  }

  validarXML() {
    // Validar si se selecciona orden de compra o no
    console.log(this.ordenSeleccionada);
    if (this.ordenSeleccionada.rfc) {
      console.log('Tiene orden de compra seleccionada');
      // Valido que el xml no exista en la orden seleccionada.
      let validacionXml = true;
      let asociadoASol: number;

      this.ordenSeleccionada.xml.forEach(xmlOrden => {
        if (xmlOrden.folioComprobante === this.xml.folioComprobante) {
          validacionXml = false;
          asociadoASol = xmlOrden.request;
          console.log(asociadoASol);
        }
      });

      // valido que el xml no exista en otra orden de compra
      if (validacionXml) {
        this.ordenesEncontradas.forEach(elementOrder => {
          elementOrder.xml.forEach(elementXml => {
            if (elementXml.folioComprobante === this.xml.folioComprobante) {
              validacionXml = false;
              asociadoASol = elementXml.request;
            }
          });
        });
      }

      if (validacionXml) {
        this.xml.asociado = true;
        this.xml.request = this.countRequest;
        this.validarProveedor();
      } else {
        Notiflix.Notify.Failure(
          `El XML ya se encuentra asociado a la solicitud ${asociadoASol}`
        );
      }
    } else {
      console.log('No tiene orden de compra seleccionada');
      // Valido que el xml no exista en la orden seleccionada.
      let validacionXml = true;
      let asociadoASol: number;

      // valido que el xml no exista en otra orden de compra
      if (validacionXml) {
        this.ordenesEncontradas.forEach(elementOrder => {
          elementOrder.xml.forEach(elementXml => {
            if (elementXml.folioComprobante === this.xml.folioComprobante) {
              validacionXml = false;
              asociadoASol = elementXml.request;
            }
          });
        });
      }
      if (validacionXml) {
        this.xml.asociado = true;
        this.xml.request = this.countRequest;
        this.validarProveedor();
      } else {
        Notiflix.Notify.Failure(
          `El XML ya se encuentra asociado a la solicitud ${asociadoASol}`
        );
      }
    }
  }

  validarProveedor() {
    // Valido que el rfc del xml sea igual al del proveedor seleccionado
    if (this.xml.rfc === this.provider.rfc) {
      console.log('El rfc si es igual al proveedor seleccionado.');
      // Si es igual, entonces valido el importe sea menor al disponible del comprometido
      // Solo aplica si tiene Orden de compra seleccionada
      let sumaAgregado = 0;
      if (this.ordenSeleccionada.rfc) {
        if (this.project.monedaLocal === this.xml.moneda) {
          if (this.xml.importe <= this.disponibleDelComprometido) {
            this.comprobante.nombreProveedor = this.provider.nombreProveedor;
            this.comprobante.folio = this.xml.folioComprobante;
            this.comprobante.importe = this.xml.importe;
            this.comprobante.iva = this.xml.iva;
            this.comprobante.total = this.xml.total;
            this.addComprobante();
          } else {
            Notiflix.Notify.Failure(
              'El valor del XML excede el valor disponible de la Orden de compra'
            );
          }
        } else {
          if (
            this.xml.importe / this.request.TC <=
            this.disponibleDelComprometido
          ) {
            this.request.moneda = this.xml.moneda;
            this.comprobante.nombreProveedor = this.provider.nombreProveedor;
            this.comprobante.folio = this.xml.folioComprobante;
            this.comprobante.importe = this.xml.importe;
            this.comprobante.iva = this.xml.iva;
            this.comprobante.total = this.xml.total;
            this.addComprobante();
          } else {
            Notiflix.Notify.Failure(
              'El valor del XML excede el valor disponible de la Orden de compra'
            );
          }
        }
      } else {
        if (this.project.monedaLocal === this.xml.moneda) {
          if (this.xml.importe <= this.disponible) {
            this.comprobante.nombreProveedor = this.provider.nombreProveedor;
            this.comprobante.folio = this.xml.folioComprobante;
            this.comprobante.importe = this.xml.importe;
            this.comprobante.iva = this.xml.iva;
            this.comprobante.total = this.xml.total;
            this.addComprobante();
          } else {
            Notiflix.Notify.Failure(
              'El valor del XML excede el valor disponible del presupuesto'
            );
          }
        } else {
          if (this.xml.importe / this.request.TC <= this.disponible) {
            this.request.moneda = this.xml.moneda;
            this.comprobante.nombreProveedor = this.provider.nombreProveedor;
            this.comprobante.folio = this.xml.folioComprobante;
            this.comprobante.importe = this.xml.importe;
            this.comprobante.iva = this.xml.iva;
            this.comprobante.total = this.xml.total;
            this.addComprobante();
          } else {
            Notiflix.Notify.Failure(
              'El valor del XML excede el valor disponible del presupuesto'
            );
          }
        }
      }
    } else {
      Notiflix.Notify.Failure(
        'El RFC del xml no corresponde al proveedor seleccionado.'
      );
    }
  }

  changeSelectProvider() {
    this.arrOrders = [] as any;
    this.ordenSeleccionada = {};
    this.comprometidoSeleccionado = {} as any;
    this.comprometidosSeleccionados = [] as any;
    this.xml = {} as any;
    this.request.valores = [] as any;
    this.comprobante = {} as any;
    this.provider = {} as any;
    let proveedor;
    if (this.xml.rfc) {
      proveedor = this.providers.find(
        provider => provider.rfc === this.xml.rfc
      );
    } else {
      const numProvider = this.proveedorSeleccionado.split('-')[0];
      proveedor = this.providers.find(
        provider => provider.numero === parseInt(numProvider)
      );
    }

    if (proveedor !== undefined) {
      this.provider.numeroProveedor = proveedor.numero;
      this.provider.nombreProveedor = proveedor.nombre;
      this.provider.rfc = proveedor.rfc;
      this.getPurchaseOrder();
    } else {
      Notiflix.Notify.Failure('El proveedor no se encuentra registrado');
      // ------
      Notiflix.Confirm.Show(
        'Agregar Proveedor',
        'Deseas agregar el proveedor a la base de datos?',
        'Si',
        'No',
        () => {
          const provider = {} as any;
          provider.nombre = this.xml.proveedor;

          provider.rfc = this.xml.rfc;

          provider.regimen = this.xml.regimen;

          provider.lugarExpedicion = this.xml.lugarExpedicion;

          provider.persona = provider.rfc.length === 12 ? 'Moral' : 'Física';
          provider.nacionalidad = 'Mexicana';
          provider.numero = this.providers.length + 1;
          this.providers.push(provider);
          const objProviders = Object.assign({}, this.providers);
          this.providersService.addProvider(objProviders).then(() => {
            Notiflix.Notify.Success('Proveedor agregado exitosamente');
            this.changeSelectProvider();
          });
        },
        () => {}
      );
    }
  }

  selectCtaIva() {
    const objIva = this.budget.find(
      element =>
        element.CodigoAgrupador.split('-')[0] ===
          this.comprobante.partidaPres.split('-')[0] &&
        element.Descripcion === 'Total Fringes'
    );
    console.log(objIva);

    if (objIva) {
      this.comprobante.partidaPresIva = objIva.CodigoAgrupador;
    } else {
      this.comprobante.partidaPresIva = '0150';
    }
  }

  getPurchaseOrder() {
    this.indexOrdenSeleccionada = undefined;
    this.arrOrders = [];
    this.getPOSubscription = this.dealMemoService
      .getPurchaseOrderRFC(this.idCompany, this.idProject, this.provider.rfc)
      .subscribe(res => {
        if (res.length === 0) {
          this.existeOrden = false;
        } else {
          console.log('tiene orden de compra pero no se a seleccionado');
          this.existeOrden = true;
        }
        this.getCountOrder();
        this.ordenesEncontradas = res;

        if (this.xml.rfc) {
          this.arrXML = [] as any;
          console.log(res);
          res.forEach(elementOrder => {
            elementOrder.xml.forEach(elementXml => {
              this.arrXML.push(elementXml);
            });
          });
          this.arrXML.concat(this.arrImportador);
          this.validarSiExiste();
        } else {
          this.arrOrders = this.ordenesEncontradas;
        }
        this.getPOSubscription.unsubscribe();
      });
  }

  validarSiExiste() {
    // Esta funcion Regresa el index del documento si encuentra el folio en el array
    const indexXML = this.arrXML.findIndex(
      element => element.folioComprobante === this.xml.folioComprobante
    );
    const XML = this.arrXML[indexXML];

    // -------------------------

    // Esta funcion Busca el rfc de empresa receptora en la empresa
    let buscarRfc = -1;
    if (this.project.filmadoras) {
      buscarRfc = this.project.filmadoras.findIndex(
        element => element.rfc === this.xml.rfcReceptor.toUpperCase()
      );
    }
    // --------------------------
    let validacionXML = true;

    if (indexXML === -1) {
      // console.log('El XML no existe en el array');
      const indexFilmadora = this.filmadoras.findIndex(
        element => element.rfc === this.xml.rfcReceptor.toUpperCase()
      );

      if (indexFilmadora >= 0) {
        validacionXML = true;
        this.xml.asociado = true;
        this.xml.request = this.countRequest;
        this.prellenarRequest();
      } else {
        console.log('El RFC receptor no corresponde a la filmadora');
        Notiflix.Notify.Failure(
          'El RFC receptor no corresponde a la filmadora'
        );
        this.provider = {} as any;
      }
    } else if (indexXML > -1) {
      // console.log('El XML ya existe');
      if (XML.asociado) {
        // console.log('y ya esta asociado');
        Notiflix.Notify.Failure(
          `El folio ${this.xml.folioComprobante} ya se encuentra asociado a la solicitud ${XML.request}.`
        );
        this.provider = {} as any;
      } else {
        // console.log('pero no esta asociado');
        this.arrXML[indexXML].asociado = true;
        this.arrXML[indexXML].request = this.countRequest;

        this.xml = this.arrXML[indexXML];

        this.request.concepto = this.arrXML[indexXML].concepto;
        this.request.valores[0].folio = this.arrXML[indexXML].folioComprobante;
        this.request.valores[0].fechaComp = this.arrXML[indexXML].fecha;
        this.request.valores[0].importe = this.arrXML[indexXML].importe;
        this.request.valores[0].iva = this.arrXML[indexXML].iva || 0;
        this.request.valores[0].retIva = this.arrXML[indexXML].retIVA || 0;
        this.request.valores[0].retIsr = this.arrXML[indexXML].retISR || 0;
        this.request.valores[0].total = this.arrXML[indexXML].total;
      }
    }
  }

  prellenarRequest() {
    this.arrOrders = this.ordenesEncontradas;
    this.request.concepto = this.xml.concepto;
    this.comprobante.nombreProveedor = this.xml.proveedor;
    this.comprobante.folio = this.xml.folioComprobante;
    this.comprobante.fechaComp = this.xml.fecha;
    this.comprobante.importe = this.xml.importe;
    this.comprobante.iva = this.xml.iva || 0;
    this.comprobante.retIva = this.xml.retIVA || 0;
    this.comprobante.retIsr = this.xml.retISR || 0;
    this.comprobante.total = this.xml.total;
    // convierto la fecha en formato aaa/mm/dd
    this.fechaComprobante = this.xml.fecha.toISOString().split('T')[0];
  }

  validarAcumulado() {
    if (this.comprometidoSeleccionado.cantidad) {
      if (
        this.request.valores[0].importe >
        this.comprometidoSeleccionado.cantidad *
          this.comprometidoSeleccionado.importe -
          this.arrAcumulados[this.indexComprometidoSeleccionado]
      ) {
        console.log('Paso 1');
        Notiflix.Notify.Failure('El importe excede el valor disponible');
        this.request.valores[0].importe = 0;
        this.request.valores[0].iva = 0;
        this.request.valores[0].retIsr = 0;
        this.request.valores[0].retIva = 0;
        this.request.valores[0].total = 0;
      }
    } else {
      console.log(this.disponible);
      if (this.request.valores[0].importe > this.disponible) {
        Notiflix.Notify.Failure('El importe excede el valor disponible');
        console.log('Paso 2');
        this.request.valores[0].importe = 0;
        this.request.valores[0].iva = 0;
        this.request.valores[0].retIsr = 0;
        this.request.valores[0].retIva = 0;
        this.request.valores[0].total = 0;
      }
    }
  }

  acumularPago() {
    this.arrOrders[this.indexOrdenSeleccionada].comprometidos[
      this.indexComprometidoSeleccionado
    ].acumulado = this.acumulado;
  }

  addComprobante() {
    // Falta Validar Que se llenen los campos
    this.request.valores.push(this.comprobante);
    this.comprobante = {} as any;
    // this.dataFile = {} as any;
  }

  createOrder() {
    this.order = {
      orderCounter: this.countDealMemo.countDealMemo,
      numeroProveedor: this.provider.numeroProveedor,
      nombreProveedor: this.provider.nombreProveedor,
      rfc: this.provider.rfc,
      comprometidos: [
        {
          partidaPres: this.request.valores[0].partidaPres,
          partidaPresIva: this.request.valores[0].partidaPresIva,
          cantidad: 1,
          importe: this.request.valores[0].importe,
          iva: this.request.valores[0].iva,
          total: this.request.valores[0].total,
          fechaInicio: this.periodoActual.fechaInicio,
          fechaFin: this.periodoActual.fechaFin,
        },
      ],
      cuentas: [],
      actividad: this.request.concepto,
      TC: this.request.TC,
      moneda: this.request.moneda,
      fechaCreado: new Date(),
      creadoPor: this.user,
      aprobadoPor: this.request.aprobadoPor,
      solicitudes: [this.request],
      tipo: 'PODIRECTA',
      calendario: true,
      xml: [],
      filmadora: this.request.filmadora,
      tipoPago: '',
    };
    if (this.xml.rfc) {
      this.order.xml.push(this.xml);
    }
    this.request.valores.forEach(element => {
      this.order.cuentas.push(element.partidaPres);
    });
  }

  saveSol() {
    console.log(this.request);
    // Se ejecuta si tiene Orden de compra
    if (this.existeOrden && this.ordenSeleccionada.rfc) {
      // this.acumularPago();
      this.request.fechaSol = new Date();
      this.request.user = this.user;

      if (this.xml.rfc) {
        console.log(this.ordenSeleccionada);
        this.ordenSeleccionada.xml.push(this.xml);
      }
      // console.log(this.arrOrders[this.indexOrdenSeleccionada]);

      this.ordenSeleccionada.solicitudes.push(this.request);
      const objUpdate = {
        solicitudes: this.ordenSeleccionada.solicitudes,
        xml: this.ordenSeleccionada.xml,
      };

      console.log(this.ordenSeleccionada.id);
      console.log(objUpdate);

      this.dealMemoService
        .updatePurchaseOrder(
          this.idCompany,
          this.idProject,
          this.ordenSeleccionada.id,
          objUpdate
        )
        .then(res => {
          Notiflix.Notify.Success('Guardado exitoso');

          const objBit = {
            fecha: new Date(),
            mensaje: `Se creo la solicitud #${this.request.countRequest}`,
            descripcion: `Por un valor de: $${this.request.valores[0].total.toFixed(
              2
            )}`,
            detalle: [
              `Proveedor: ${this.provider.nombreProveedor}`,
              `RFC: ${this.provider.rfc}`,
              `Concepto: ${this.request.concepto}`,
              `Partida: ${this.request.valores[0].partidaPres || 'Sin folio'}`,
              `Folio: ${this.request.valores[0].folio || 'Sin folio'}`,
              `Aprobado por: ${this.request.aprobadoPor}`,
            ],
            id: this.arrOrders[this.indexOrdenSeleccionada].id,
            path: `empresas/${this.idCompany}/proyectos/${
              this.idProject
            }/purchaseOrder/${this.arrOrders[this.indexOrdenSeleccionada].id}`,
            tipo: 'Solicitud',
            user: this.user,
          } as any;
          this.updateBitacora(objBit);

          this.clear();
        })
        .catch(err => {
          Notiflix.Notify.Failure('Ocurrió un error');
          console.error('ERROR: ', err);
        });
    } else {
      console.log('No tiene orden de compra seleccionada');
      // Se ejecuta si no tiene Orden de compra
      // Validar disponible en el presupuesto
      this.request.fechaSol = new Date();
      this.request.user = this.user;
      this.createOrder();
      console.log(this.order);
      this.dealMemoService
        .addPurchaseOrder(this.idCompany, this.idProject, this.order)
        .then(res => {
          console.log(res);
          Notiflix.Notify.Success('Guardado exitoso');

          const objBit = {
            fecha: new Date(),
            mensaje: `Se creo la orden #${this.countDealMemo.countDealMemo} con la solicitud  #${this.request.countRequest}`,
            descripcion: `Por un valor de: $${this.request.valores[0].total.toFixed(
              2
            )}`,
            detalle: [
              `Proveedor: ${this.provider.nombreProveedor}`,
              `RFC: ${this.provider.rfc}`,
              `Concepto: ${this.request.concepto}`,
              `Partida: ${this.request.valores[0].partidaPres || 'Sin folio'}`,
              `Folio: ${this.request.valores[0].folio || 'Sin folio'}`,
              `Aprobado por: ${this.request.aprobadoPor}`,
            ],
            id: res.id,
            path: `empresas/${this.idCompany}/proyectos/${this.idProject}/purchaseOrder/${res.id}`,
            tipo: 'Solicitud',
            user: this.user,
          } as any;
          this.updateBitacora(objBit);

          this.clear();

          // Actualizo contador de ordenes de compra
          this.dealMemoService.countDealMemo(
            this.idCompany,
            this.idProject,
            this.countDealMemo
          );
        })
        .catch(err => {
          Notiflix.Notify.Failure('Ocurrió un error');
          console.error('ERROR: ', err);
        });
    }
  }

  updateBitacora(obj) {
    this.generalService.updateBit(this.idCompany, this.idProject, obj);
  }

  clear() {
    // Guardamos Count Request
    this.arrCountRequest.push(this.countRequest);
    this.requestService.countRequest(
      this.idCompany,
      this.idProject,
      this.arrCountRequest
    );

    // Si esta desuscrito se vuelve a subscribir
    if (this.subscribeCountRequest) {
      this.getCount();
    }
    this.countRequest++;
    // >>>>>>>>>>>>>>>>>>

    this.request = {
      countRequest: 0,
      concepto: '',
      cfdi: {
        path: '',
        url: '',
      },
      fechaSol: new Date(),
      user: '',
      tipo: '',
      moneda: '',
      TC: 0,
      periodo: '',
      etapa: '',
      filmadora: {
        name: '',
        rfc: '',
      },
      aprobadoPor: '',
      valores: [
        {
          concepto: '',
          fechaComp: null,
          folio: '',
          importe: 0,
          iva: 0,
          retIva: 0,
          retIsr: 0,
          total: 0,
          nombreProveedor: '',
          partidaPres: '',
          partidaPresIva: '',
          fechaInicio: this.periodoActual.fechaInicio,
          fechaFin: this.periodoActual.fechaFin,
          rfc: '',
        },
      ],
      deducible: '',
    };
    this.arrOrders = [] as any;
    this.ordenSeleccionada = [] as any;
    this.provider = {} as any;
    this.request.moneda = this.project.monedaLocal;
    this.request.TC = this.project.tipoCambioGral;
    this.request.filmadora = this.filmadoras[0];
    this.fechaComprobante = '';
    this.indexOrdenSeleccionada = undefined;
    this.indexComprometidoSeleccionado = undefined;
    this.comprometidosSeleccionados = [] as any;
    this.comprometidoSeleccionado = {} as any;
    this.disponibleCuenta = 0;
    this.acumuladoCuenta = 0;
    this.arrXML = [] as any;
    this.xml = {} as any;
    this.proveedorSeleccionado = '';
    this.filtrarperiodo();
  }

  ngOnDestroy(): void {
    this.getProviderSubscription.unsubscribe();
    this.subscribeCountRequest.unsubscribe();
    this.getCompanySubscription.unsubscribe();
    this.subscriberGetProject.unsubscribe();
    this.subscriberGetUser.unsubscribe();
    this.subscriberBudget.unsubscribe();
    // this.subscribeGetCFDI.unsubscribe();
    if (this.subscribeCountDealMemo) {
      this.subscribeCountDealMemo.unsubscribe();
    }

    if (this.subscriberGetPurchaseOrder) {
      this.subscriberGetPurchaseOrder.unsubscribe();
    }
  }
}
