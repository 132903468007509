import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProvidersService } from 'src/app/services/providers.service';
import { Subscription } from 'rxjs';
import { RequestService } from 'src/app/services/request.service';
import { NgxXml2jsonService } from 'ngx-xml2json';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

import Notiflix from 'notiflix-angular';

import { Router } from '@angular/router';
import { BudgetService } from 'src/app/services/budget.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { AuthService } from 'src/app/services/auth.service';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { Solicitud, Valores } from 'src/app/models/solicitud';
import { ProjectService } from 'src/app/services/project.service';
import { Period } from 'src/app/models/period';
import { PurchaseOrder } from 'src/app/models/purchaseOrder';
import { GeneralService } from 'src/app/services/general.service';

declare var $: any;

@Component({
  selector: 'app-sol-gxcv2',
  templateUrl: './sol-gxcv2.component.html',
  styleUrls: ['./sol-gxcv2.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.5s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class SolGxcv2Component implements OnInit, OnDestroy {
  providers = [] as any;
  provider = {} as any;
  proveedorSeleccionado = '';

  steps = {
    paso1: true,
    paso2: false,
    paso3: false,
    paso4: false,
    paso5: false,
    paso6: false,
  } as any;

  countRequest: number;
  idCompany: string;
  idProject: string;
  company = {} as any;
  project = {} as any;

  arrCountRequest = [] as any;
  order = {} as PurchaseOrder;
  request: Solicitud;
  budget = [] as any;
  fileError = [] as any;
  arrXML = [] as any;
  arrXMLGeneral = [] as any;
  xml = {} as any;
  nameXML: string;
  public rfcReceptor = '';
  user: string;
  periods = [] as any;
  periodoActual = {} as Period;
  binding: any;
  changePeriod = false;
  arrayFechas = [] as any;
  filmadoras = [];
  changeFilmadora = false;
  ordenesEncontradas = [] as any;
  changeCountRequest = false;
  arrImportador = [] as any;
  arrOrders = [] as any;
  indexOrdenSeleccionada: number = undefined;
  indexComprometidoSeleccionado: number = undefined;
  ordenSeleccionada = {} as any;
  comprometidosSeleccionados = [] as any;
  comprometidoSeleccionado = {} as any;
  fechaComprobante: string;
  acumulado = 0;
  countDealMemo = {} as any;
  existeOrden: boolean;
  editCuenta = false;
  acumuladoTemporal = 0;
  disponible = 0;
  acumuladoCuenta = 0;
  disponibleCuenta = 0;
  addValue = {} as any;
  arrAcumulados = [] as any;
  bitacora = [] as any;
  sumas = {
    importe: 0,
    iva: 0,
    total: 0,
  } as any;
  numPO: number;

  arrDeXml = [] as any;
  comprobante = {
    partidaPres: 'Selecciona una partida',
  } as Valores;

  // Subscriptions
  getProviderSubscription: Subscription;
  subscribeCountRequest: Subscription;
  getCompanySubscription: Subscription;
  subscriberGetProject: Subscription;
  subscriberGetUser: Subscription;
  subscriberBudget: Subscription;
  subscriberGetPurchaseOrder: Subscription;
  subscribePeriods: Subscription;
  subscribeGetCFDI: Subscription;
  subscribeCountDealMemo: Subscription;
  getPOSubscription: Subscription;
  findOrderSubscription: Subscription;

  constructor(
    private providersService: ProvidersService,
    private requestService: RequestService,
    private router: Router,
    private ngxXml2jsonService: NgxXml2jsonService,
    private budgetService: BudgetService,
    private dealMemoService: DealMemoService,
    private empresasService: EmpresasService,
    private authService: AuthService,
    private projectService: ProjectService,
    private generalService: GeneralService
  ) {
    this.request = {
      countRequest: undefined,
      concepto: '',
      cfdi: {
        path: '',
        url: '',
      },
      fechaSol: undefined,
      user: '',
      tipo: 'REEMBOLSO',
      moneda: '',
      TC: 0,
      periodo: '',
      etapa: '',
      filmadora: {
        name: '',
        rfc: '',
      },
      aprobadoPor: '',
      valores: [],
      deducible: 'deducible',
    };
    // {
    //   concepto: '',
    //   fechaComp: null,
    //   folio: '',
    //   importe: 0,
    //   iva: 0,
    //   retIva: 0,
    //   retIsr: 0,
    //   total: 0,
    //   nombreProveedor: '',
    //   partidaPres: '',
    //   partidaPresIva: '',
    //   fechaInicio: undefined,
    //   fechaFin: undefined,
    //   rfc: '',
    // },
  }

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getCount();
    this.getPeriods();
    this.getCompany();
    this.getBudget();
    this.getProvider();
    this.getXML();
    this.getUser();
  }

  getCompany() {
    this.getCompanySubscription = this.empresasService
      .getCompanyActual(this.idCompany)
      .subscribe((res: any) => {
        const objFilmadora = {
          name: res.name,
          rfc: res.rfc,
        };
        this.filmadoras.push(objFilmadora);
        this.company = res;
        this.rfcReceptor = res.rfc.toUpperCase();
        this.getProject();
      });
  }

  getProject() {
    this.subscriberGetProject = this.empresasService
      .getProjectSpecific(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        this.project = res;
        this.request.moneda = res.monedaLocal;
        this.request.TC = res.tipoCambioGral;

        if (res.filmadoras) {
          this.filmadoras = this.filmadoras.concat(res.filmadoras);
        }
        this.request.filmadora = this.filmadoras[0];
      });
  }

  selectFilmadora() {
    this.changeFilmadora = false;
  }

  getUser() {
    this.subscriberGetUser = this.authService.userData$.subscribe(res => {
      this.user = res.email;
    });
  }

  getPeriods() {
    this.subscribePeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periods = Object.assign([], res);
        this.filtrarperiodo();
      });
  }
  filtrarperiodo() {
    const fecha = new Date();
    for (let index = 0; index < this.periods.length; index++) {
      const element = this.periods[index];
      const inicio = new Date(element.fechaInicio + 'T00:00:00');
      const fin = new Date(element.fechaFin + 'T23:59:59');
      if (inicio <= fecha && fin >= fecha) {
        this.periodoActual = element;
        this.request.periodo = element.nombrePeriodo;
        this.request.etapa = element.etapa;
        // this.request.fechaInicio = inicio;
        // this.request.fechaFin = fin;
        break;
      }
    }
  }
  cambiarPeriodoActual() {
    this.periodoActual = this.binding;
    this.request.periodo = this.binding.nombrePeriodo;
    // this.request.fechaInicio = this.binding.fechaInicio;
    // this.request.fechaFin = this.binding.fechaFin;
    this.request.etapa = this.binding.etapa;
    this.changePeriod = false;
  }
  getCount() {
    this.subscribeCountRequest = this.requestService
      .getCountRequest(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        if (res === undefined) {
          this.countRequest = 1;
          this.request.countRequest = this.countRequest;
        } else {
          this.arrCountRequest = res.countRequest;
          this.countRequest = this.arrCountRequest.length + 1;
          for (let index = 0; index < res.countRequest.length; index++) {
            const element = res.countRequest[index];
            if (element !== index + 1) {
              this.countRequest = res.countRequest[index - 1] + 1;
              break;
            }
            this.request.countRequest = this.countRequest;
          }
        }
      });
  }

  countRequestManual() {
    if (!this.subscribeCountRequest.closed) {
      this.subscribeCountRequest.unsubscribe();
    }
    for (let index = 0; index < this.arrCountRequest.length; index++) {
      const element = this.arrCountRequest[index];
      this.request.countRequest = this.countRequest;
      if (element === this.countRequest) {
        Notiflix.Notify.Failure(
          `La solicitud numero ${this.countRequest} ya existe`
        );
        this.getCount();
        break;
      }
    }
  }

  getProvider() {
    this.getProviderSubscription = this.providersService
      .getProvider()
      .subscribe(res => {
        this.providers = Object.assign([], res);
      });
  }

  getCountOrder() {
    this.subscribeCountDealMemo = this.dealMemoService
      .getCountDealMemo(this.idCompany, this.idProject)
      .subscribe(res => {
        const contador: any = res;
        if (res === undefined) {
          this.countDealMemo.countDealMemo = 1;
        } else {
          this.countDealMemo.countDealMemo = contador.countDealMemo + 1;
        }
        this.order.orderCounter = this.countDealMemo.countDealMemo;
      });
  }

  seleccionOrden(item, i) {
    console.log('Orden Seleccionada', item);
    this.ordenSeleccionada = item;
    this.ordenSeleccionada.xml = [] as any;
    this.indexOrdenSeleccionada = i;
    this.arrayFechas = [] as any;

    this.arrAcumulados = [] as any;

    this.request.valores = [] as any;
    // item.solicitudes = [] as any;
    this.request.moneda = item.moneda;
    this.request.TC = item.TC;
    this.request.aprobadoPor = item.aprobadoPor;
    this.request.concepto = item.actividad;

    item.comprometidos.forEach(elementComprometidos => {
      this.request.valores.push({
        concepto: elementComprometidos.concepto || '',
        fechaComp: elementComprometidos.fechaComp || null,
        folio: elementComprometidos.folio || '',
        importe: elementComprometidos.importe,
        iva: elementComprometidos.iva || 0,
        retIva: elementComprometidos.retIva || 0,
        retIsr: elementComprometidos.retIsr || 0,
        total: elementComprometidos.total,
        nombreProveedor: elementComprometidos.nombreProveedor,
        partidaPres: elementComprometidos.partidaPres || '',
        partidaPresIva: elementComprometidos.partidaPresIva || null,
        fechaInicio: elementComprometidos.fechaInicio || null,
        fechaFin: elementComprometidos.fechaFin || null,
        rfc: elementComprometidos.rfc || '',
      });
    });
  }

  findOrders() {
    this.findOrderSubscription = this.dealMemoService
      .findOrders(this.idCompany, this.idProject, this.comprobante.partidaPres)
      .subscribe(res => {
        console.log('Ordenes encontradas', res);
        this.acumuladoTemporal = 0;
        this.disponible = 0;

        if (res.length > 0) {
          res.forEach(elementOrder => {
            elementOrder.comprometidos.forEach(elementComprometidos => {
              if (
                elementComprometidos.partidaPres ===
                this.comprobante.partidaPres
              ) {
                let comprometido = 0;
                if (this.project.monedaLocal === this.request.moneda) {
                  comprometido =
                    elementComprometidos.importe *
                    elementComprometidos.cantidad;
                  this.acumuladoTemporal += comprometido;
                } else {
                  comprometido =
                    elementComprometidos.importe *
                    this.request.TC *
                    elementComprometidos.cantidad;
                  this.acumuladoTemporal += comprometido;
                }
              }
            });
          });
          const obj = this.budget.find(
            element => element.CodigoAgrupador === this.comprobante.partidaPres
          );

          let suma = 0;
          this.request.valores.forEach(element => {
            suma += element.importe;
          });

          this.disponible =
            obj.Importe_Estimado - this.acumuladoTemporal - suma;
        } else {
          const obj = this.budget.find(
            element => element.CodigoAgrupador === this.comprobante.partidaPres
          );
          let suma = 0;
          this.request.valores.forEach(element => {
            suma += element.importe;
          });
          if (this.project.monedaLocal === this.request.moneda) {
            this.disponible = obj.Importe_Estimado - suma;
          } else {
            this.disponible = obj.Importe_Estimado * this.request.TC - suma;
          }
        }

        this.acumuladoCuenta = this.acumuladoTemporal;
        this.disponibleCuenta = this.disponible;

        console.log('AcumuladoTemporal: ' + this.acumuladoTemporal);
        console.log('Disponible Presupuesto: ' + this.disponible);

        this.findOrderSubscription.unsubscribe();
      });
  }

  // selectComprometido(item, i) {
  //   this.comprometidoSeleccionado = item;
  //   this.indexComprometidoSeleccionado = i;

  //   this.findOrderSubscription = this.dealMemoService
  //     .findOrders(this.idCompany, this.idProject, item.partidaPres)
  //     .subscribe(res => {
  //       this.acumuladoTemporal = 0;
  //       this.disponible = 0;
  //       if (res.length > 0) {
  //         res.forEach(elementOrder => {
  //           elementOrder.comprometidos.forEach(elementComprometidos => {
  //             if (elementComprometidos.partidaPres === item.partidaPres) {
  //               const comprometido =
  //                 elementComprometidos.importe * elementComprometidos.cantidad;
  //               this.acumuladoTemporal += comprometido;
  //             }
  //           });
  //         });
  //         const obj = this.budget.find(
  //           element => element.CodigoAgrupador === item.partidaPres
  //         );

  //         // let suma = 0;
  //         // this.po.comprometidos.forEach(element => {
  //         //   suma += element.cantidad * element.importe;
  //         // });

  //         if (this.project.monedaLocal === this.ordenSeleccionada.moneda) {
  //           this.disponible = obj.Importe_Estimado - this.acumuladoTemporal;
  //         } else {
  //           this.disponible =
  //             obj.Importe_Estimado * this.ordenSeleccionada.TC -
  //             this.acumuladoTemporal;
  //         }
  //       }

  //       // this.request.importe = item.importe;
  //       // this.request.iva = this.request.importe * 0.16;
  //       // this.request.total = this.request.iva + item.importe;

  //       console.log('AcumuladoTemporal: ' + this.acumuladoTemporal);
  //       console.log('Disponible Presupuesto: ' + this.disponible);

  //       let importesRequest = 0;
  //       this.request.valores.forEach(element => {
  //         importesRequest += element.importe;
  //       });

  //       if (
  //         importesRequest <=
  //         item.importe * item.cantidad -
  //           this.arrAcumulados[this.indexComprometidoSeleccionado]
  //       ) {
  //         this.acumulado =
  //           this.arrAcumulados[this.indexComprometidoSeleccionado];
  //         this.request.partidaPres = item.partidaPres;
  //         this.request.aprobadoPor =
  //           this.arrOrders[this.indexOrdenSeleccionada].aprobadoPor;
  //         this.request.moneda = this.ordenSeleccionada.moneda;
  //         this.request.TC = this.ordenSeleccionada.TC;
  //       } else {
  //         Notiflix.Notify.Failure('El importe excede el valor disponible');
  //         Notiflix.Confirm.Show(
  //           'Excede valor disponible',
  //           '¿Deseas modificar la cantidad o el valor de la orden de compra?',
  //           'Si',
  //           'No',
  //           () => {
  //             $('#modalEditCtas').modal('show');
  //           }
  //         );
  //         this.indexComprometidoSeleccionado = undefined;
  //       }

  //       this.findOrderSubscription.unsubscribe();
  //     });
  // }

  changeDeducible() {
    this.sumas.importe = 0;
    this.sumas.iva = 0;
    this.sumas.total = 0;
    this.request.valores.forEach(element => {
      if (this.request.deducible === 'deducible') {
        element.iva = 0;
        element.total = 0;
      } else {
        element.iva = 0;
        element.total = element.importe;
      }
      this.sumas.importe += element.importe;
      this.sumas.iva += element.iva;
      this.sumas.total += element.total;
    });
  }

  validarDisponible() {
    this.addValue.cantidad;
    let newValues = {} as any;

    newValues.cantidad =
      this.addValue.cantidad + this.comprometidoSeleccionado.cantidad;
    newValues.importe =
      this.addValue.importe + this.comprometidoSeleccionado.importe;

    const newValue = newValues.cantidad * newValues.importe;

    const total =
      this.comprometidoSeleccionado.cantidad *
      this.comprometidoSeleccionado.importe;

    const totalAComprometer = newValue - total;

    if (totalAComprometer <= this.disponible) {
      this.comprometidoSeleccionado.cantidad = newValues.cantidad;
      this.comprometidoSeleccionado.importe = newValues.importe;
      this.disponible -= totalAComprometer;
    } else {
      Notiflix.Notify.Failure(
        'La nueva cantidad e importe excede el disponible del presupuesto'
      );
    }
  }

  guardarModalCuentas() {
    $('#modalEditCtas').modal('show');
  }

  getBudget() {
    this.subscriberBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          const arrBudget = this.budgetService.ctasFilter2(budget); // Esta función desglosa todo el presupuesto
          //console.log(budget); // este es el array que contiene hijos (children)
          let arrFilter = arrBudget.filter(
            obj => obj.CodigoAgrupador.split('-').length === 3
          );
          this.budget = arrFilter; // Este es el array que mostramos en la lista
          // console.log(this.budget);
        }
      });
  }

  getXML() {
    this.subscribeGetCFDI = this.projectService
      .getXML(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        if (res !== undefined) {
          this.arrImportador = res.arrXML;
        }
      });
  }

  dataFile = {} as any;
  onFileChange(ev, item) {
    for (let index = 0; index < ev.target.files.length; index++) {
      const archivo = ev.target.files[index];
      this.dataFile = archivo;
      if (archivo.type === 'text/xml') {
        const lector = new FileReader();
        lector.onload = e => {
          this.xmlToJson(e, archivo, item);
        };
        lector.readAsText(archivo);
      } else {
        Notiflix.Notify.Failure(
          `El archivo ${archivo.name} no es un archivo XML`
        );
        // (<any>document.getElementById('file-3')).value = '';
      }
    }
  }

  xmlToJson(lector, archivo, item) {
    console.log(item);
    const res = lector.target.result;
    const parser = new DOMParser();
    const xmlData = parser.parseFromString(res, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(xmlData);

    this.xml = this.generalService.assignDataCFDI(obj);
    console.log(this.xml);

    // Validar el rfc receptor
    let buscarRfc = -1;
    if (this.project.filmadoras) {
      this.project.filmadoras.push({ rfc: this.company.rfc });
    } else {
      this.project.filmadoras = [] as any;
      this.project.filmadoras.push({ rfc: this.company.rfc });
    }
    buscarRfc = this.project.filmadoras.findIndex(
      element => element.rfc === this.xml.rfcReceptor.toUpperCase()
    );

    if (buscarRfc === -1) {
      Notiflix.Notify.Failure(
        'El RFC Receptor no corresponde a esta empresa o filmadora'
      );
    }

    if (this.steps.paso3) {
      if (this.xml.importe <= this.disponible && buscarRfc > -1) {
        this.comprobante.concepto = this.xml.concepto;
        this.comprobante.folio = this.xml.folioComprobante;
        this.comprobante.fechaComp = this.xml.fecha;
        this.comprobante.nombreProveedor = this.xml.proveedor;
        this.comprobante.rfc = this.xml.rfc;
        this.comprobante.importe = this.xml.importe;
        this.comprobante.iva = this.xml.iva;
        this.comprobante.total = this.xml.total;
        // this.request.xml.push(this.xml);
        this.addComprobante();
      } else {
        Notiflix.Notify.Failure('El importe excede el limite del presupuesto');
        this.sumas.importe -= this.xml.importe;
        this.sumas.iva -= this.xml.iva;
        this.sumas.total = this.sumas.importe + this.sumas.iva;
        this.dataFile = {} as any;
      }
    } else {
      if (this.xml.importe === item.importe && buscarRfc > -1) {
        item.concepto = this.xml.concepto;
        item.folio = this.xml.folioComprobante;
        item.fechaComp = this.xml.fecha;
        item.nombreProveedor = this.xml.proveedor;
        item.rfc = this.xml.rfc;
        item.importe = this.xml.importe;
        item.iva = this.xml.iva;
        item.total = this.xml.total;
        // this.request.xml.push(this.xml);
      } else {
        Notiflix.Notify.Failure(
          'El importe no es igual al valor de la orden de compra'
        );
      }
    }

    this.sumas.importe += this.xml.importe;
    this.sumas.iva += this.xml.iva;
    this.sumas.total = this.sumas.importe + this.sumas.iva;

    // this.changeSelectProvider();
  }

  changeSelectProvider() {
    this.arrOrders = [] as any;
    this.comprometidoSeleccionado = {} as any;
    this.comprometidosSeleccionados = [] as any;
    let proveedor;

    const numProvider = this.proveedorSeleccionado.split('-')[0];
    proveedor = this.providers.find(
      provider => provider.numero === parseInt(numProvider)
    );

    if (proveedor !== undefined) {
      this.provider.numeroProveedor = proveedor.numero;
      this.provider.nombreProveedor = proveedor.nombre;
      this.provider.rfc = proveedor.rfc;
      this.getCountOrder();
    } else {
      Notiflix.Notify.Failure('El proveedor no se encuentra registrado');
      // ------
      Notiflix.Confirm.Show(
        'Agregar Proveedor',
        'Deseas agregar el proveedor a la base de datos?',
        'Si',
        'No',
        () => {
          const provider = {} as any;
          provider.nombre = this.xml.proveedor;

          provider.rfc = this.xml.rfc;

          provider.regimen = this.xml.regimen;

          provider.lugarExpedicion = this.xml.lugarExpedicion;

          provider.persona = provider.rfc.length === 12 ? 'Moral' : 'Física';
          provider.nacionalidad = 'Mexicana';
          provider.numero = this.providers.length + 1;
          this.providers.push(provider);
          const objProviders = Object.assign({}, this.providers);
          this.providersService.addProvider(objProviders).then(() => {
            Notiflix.Notify.Success('Proveedor agregado exitosamente');
            this.changeSelectProvider();
          });
        },
        () => {}
      );
    }
  }

  selectCtaIva() {
    const objIva = this.budget.find(
      element =>
        element.CodigoAgrupador.split('-')[0] ===
          this.comprobante.partidaPres.split('-')[0] &&
        element.Descripcion === 'Total Fringes'
    );
    console.log(objIva);

    if (objIva) {
      this.comprobante.partidaPresIva = objIva.CodigoAgrupador;
    } else {
      this.comprobante.partidaPresIva = '0150';
    }
  }

  getPurchaseOrderNum(num) {
    this.restart();
    this.getPOSubscription = this.dealMemoService
      .getPurchaseOrderNum(this.idCompany, this.idProject, num)
      .subscribe(res => {
        if (res.length === 0) {
          this.existeOrden = false;
          this.getCountOrder();
        } else {
          this.existeOrden = true;
        }
        this.ordenesEncontradas = res;

        if (this.xml.rfc) {
          this.arrXML = [] as any;
          res.forEach(elementOrder => {
            elementOrder.comprometidos.forEach(elementComprometidos => {
              elementComprometidos.solicitudes.forEach(elementSolicitudes => {
                this.arrXML.push(elementSolicitudes.xml);
              });
            });
          });
          this.arrXML.concat(this.arrImportador);
          this.validarSiExiste();
        } else {
          this.arrOrders = this.ordenesEncontradas;
        }
        this.getPOSubscription.unsubscribe();
      });
  }

  restart() {
    this.indexOrdenSeleccionada = undefined;
    this.arrOrders = [];
    this.ordenSeleccionada = {};
    this.request.valores = [];
    this.steps = {
      paso1: false,
      paso2: true,
      paso3: false,
      paso4: false,
      paso5: false,
      paso6: false,
    } as any;
    delete this.request.concepto;
    delete this.request.aprobadoPor;
  }

  validarSiExiste() {
    console.log('se ejecuta validarSiExiste()');
    // Regresa el index si encuentra el folio
    const indexXML = this.arrXML.findIndex(
      element => element.folioComprobante === this.xml.folioComprobante
    );
    const XML = this.arrXML[indexXML];

    // -------------------------

    // Buscar rfc de empresa receptora
    let buscarRfc = -1;
    if (this.project.filmadoras) {
      buscarRfc = this.project.filmadoras.findIndex(
        element => element.rfc === this.xml.rfcReceptor.toUpperCase()
      );
    }
    // --------------------------
    let validacionXML = true;

    if (indexXML === -1) {
      const indexFilmadora = this.filmadoras.findIndex(
        element => element.rfc === this.xml.rfcReceptor.toUpperCase()
      );

      if (indexFilmadora >= 0) {
        validacionXML = true;
        this.xml.asociado = true;
        this.xml.request = this.countRequest;
        // this.prellenarRequest();
      } else {
        console.log('El RFC receptor no corresponde a la filmadora');
        Notiflix.Notify.Failure(
          'El RFC receptor no corresponde a la filmadora'
        );
        this.provider = {} as any;
      }
    } else if (indexXML > -1) {
      // console.log('El XML ya existe');
      if (XML.asociado) {
        // console.log('y ya esta asociado');
        Notiflix.Notify.Failure(
          `El folio ${this.xml.folioComprobante} ya se encuentra asociado con la solicitud ${XML.request}.`
        );
        this.provider = {} as any;
      } else {
        // console.log('pero no esta asociado');
        this.arrXML[indexXML].asociado = true;
        this.arrXML[indexXML].request = this.countRequest;

        this.xml = this.arrXML[indexXML];

        // this.request.concepto = this.arrXML[indexXML].concepto;
        // this.request.folio = this.arrXML[indexXML].folioComprobante;
        // this.request.fechaComp = this.arrXML[indexXML].fecha;
        // this.request.importe = this.arrXML[indexXML].importe;
        // this.request.iva = this.arrXML[indexXML].iva;
        // this.request.retIva = this.arrXML[indexXML].retIVA || 0;
        // this.request.retIsr = this.arrXML[indexXML].retISR || 0;
        // this.request.total = this.arrXML[indexXML].total;
      }
    }
  }

  // prellenarRequest() {
  //   this.arrOrders = this.ordenesEncontradas;
  //   this.request.concepto = this.xml.concepto;
  //   this.request.folio = this.xml.folioComprobante;
  //   this.request.fechaComp = this.xml.fecha;
  //   this.request.importe = this.xml.importe;
  //   this.request.iva = this.xml.iva;
  //   this.request.retIva = this.xml.retIVA || 0;
  //   this.request.retIsr = this.xml.retISR || 0;
  //   this.request.total = this.xml.total;
  //   // convierto la fecha en formato aaa/mm/dd
  //   this.fechaComprobante = this.xml.fecha.toISOString().split('T')[0];
  // }

  // validarAcumulado() {
  //   if (this.comprometidoSeleccionado.cantidad) {
  //     if (
  //       this.request.importe >
  //       this.comprometidoSeleccionado.cantidad *
  //         this.comprometidoSeleccionado.importe -
  //         this.arrAcumulados[this.indexComprometidoSeleccionado]
  //     ) {
  //       // console.log('Paso 1');
  //       Notiflix.Notify.Failure('El importe excede el valor disponible');
  //       this.request.importe = 0;
  //       this.request.iva = 0;
  //       this.request.retIsr = 0;
  //       this.request.retIva = 0;
  //       this.request.total = 0;
  //     }
  //   } else {
  //     if (this.request.importe > this.disponible) {
  //       // console.log('Paso 2');
  //       Notiflix.Notify.Failure('El importe excede el valor disponible');
  //       this.request.importe = 0;
  //       this.request.iva = 0;
  //       this.request.retIsr = 0;
  //       this.request.retIva = 0;
  //       this.request.total = 0;
  //     }
  //   }
  // }

  acumularPago() {
    this.arrOrders[this.indexOrdenSeleccionada].comprometidos[
      this.indexComprometidoSeleccionado
    ].acumulado = this.acumulado;
  }

  addComprobante() {
    // Validar Que se llenen los campos
    this.request.valores.push(this.comprobante);
    this.comprobante = {} as any;
    this.dataFile = {} as any;
  }

  createOrder() {
    this.order = {
      orderCounter: this.countDealMemo.countDealMemo,
      numeroProveedor: this.provider.numeroProveedor,
      nombreProveedor: this.provider.nombreProveedor,
      rfc: this.provider.rfc,
      comprometidos: [],
      cuentas: [],
      actividad: this.request.concepto,
      TC: this.request.TC,
      moneda: this.request.moneda,
      fechaCreado: new Date(),
      creadoPor: this.user,
      solicitudes: [],
      aprobadoPor: this.request.aprobadoPor,
      tipo: 'POREEMBOLSO',
      calendario: false,
    } as PurchaseOrder;

    this.request.valores.forEach(elementComp => {
      const comprometido = {
        partidaPres: elementComp.partidaPres,
        partidaPresIva: elementComp.partidaPresIva,
        cantidad: 1,
        importe: elementComp.importe,
        iva: elementComp.iva,
        total: elementComp.total,
      };
      this.order.cuentas.push(elementComp.partidaPres);
      this.order.comprometidos.push(comprometido);
    });
    console.log(this.order);
  }

  saveSol() {
    console.log(this.ordenSeleccionada);
    // console.log(this.request);
    // Se ejecuta si tiene Orden de compra
    if (this.existeOrden) {
      // this.acumularPago();
      this.request.fechaSol = new Date();
      this.request.user = this.user;

      // console.log(this.arrOrders[this.indexOrdenSeleccionada]);
      this.ordenSeleccionada.xml.push(this.xml);
      this.ordenSeleccionada.solicitudes.push(this.request);

      const objUpdate = {
        solicitudes: this.ordenSeleccionada.solicitudes,
      };

      this.dealMemoService
        .updatePurchaseOrder(
          this.idCompany,
          this.idProject,
          this.ordenSeleccionada.id,
          objUpdate
        )
        .then(res => {
          Notiflix.Notify.Success('Guardado exitoso');

          // const objBit = {
          //   fecha: new Date(),
          //   mensaje: `Se creo la solicitud #${this.request.countRequest}`,
          //   descripcion: `Por un valor de: $${this.request.total.toFixed(2)}`,
          //   detalle: [
          //     `Proveedor: ${this.provider.nombreProveedor}`,
          //     `RFC: ${this.provider.rfc}`,
          //     `Concepto: ${this.request.concepto}`,
          //     `Partida: ${this.request.partidaPres || 'Sin folio'}`,
          //     `Folio: ${this.request.folio || 'Sin folio'}`,
          //     `Aprobado por: ${this.request.aprobadoPor}`,
          //   ],
          //   id: this.arrOrders[this.indexOrdenSeleccionada].id,
          //   path: `empresas/${this.idCompany}/proyectos/${
          //     this.idProject
          //   }/purchaseOrder/${this.arrOrders[this.indexOrdenSeleccionada].id}`,
          //   tipo: 'Solicitud',
          //   user: this.user,
          // } as any;
          // this.updateBitacora(objBit);

          this.clear();
        })
        .catch(err => {
          Notiflix.Notify.Failure('Ocurrió un error');
          console.error('ERROR: ', err);
        });
    } else {
      // Se ejecuta si no tiene Orden de compra
      // Validar disponible en el presupuesto
      this.createOrder();

      this.request.fechaSol = new Date();
      this.request.user = this.user;

      let sumaImportes = 0;
      let sumaIva = 0;
      let sumaTotal = 0;
      this.request.valores.forEach(element => {
        sumaImportes += element.importe;
        sumaIva += element.iva;
        sumaTotal += element.total;
      });
      // this.request.importe = sumaImportes;
      // this.request.iva = sumaIva;
      // this.request.total = sumaTotal;

      this.order.solicitudes.push(this.request);

      // console.log(this.order);
      this.dealMemoService
        .addPurchaseOrder(this.idCompany, this.idProject, this.order)
        .then(res => {
          // console.log(res);
          Notiflix.Notify.Success('Guardado exitoso');

          // const objBit = {
          //   fecha: new Date(),
          //   mensaje: `Se creo la orden #${this.countDealMemo.countDealMemo} con la solicitud  #${this.request.countRequest}`,
          //   descripcion: `Por un valor de: $${this.request.total.toFixed(2)}`,
          //   detalle: [
          //     `Proveedor: ${this.provider.nombreProveedor}`,
          //     `RFC: ${this.provider.rfc}`,
          //     `Concepto: ${this.request.concepto}`,
          //     `Partida: ${this.request.partidaPres || 'Sin folio'}`,
          //     `Folio: ${this.request.folio || 'Sin folio'}`,
          //     `Aprobado por: ${this.request.aprobadoPor}`,
          //   ],
          //   id: res.id,
          //   path: `empresas/${this.idCompany}/proyectos/${this.idProject}/purchaseOrder/${res.id}`,
          //   tipo: 'Solicitud',
          //   user: this.user,
          // } as any;
          // this.updateBitacora(objBit);

          this.clear();

          // Actualizo contador de ordenes de compra
          this.dealMemoService.countDealMemo(
            this.idCompany,
            this.idProject,
            this.countDealMemo
          );
        })
        .catch(err => {
          Notiflix.Notify.Failure('Ocurrió un error');
          console.error('ERROR: ', err);
        });
    }
  }

  updateBitacora(obj) {
    this.generalService.updateBit(this.idCompany, this.idProject, obj);
  }

  clear() {
    // Guardamos Count Request
    this.arrCountRequest.push(this.countRequest);
    this.requestService.countRequest(
      this.idCompany,
      this.idProject,
      this.arrCountRequest
    );

    // Si esta desuscrito se vuelve a subscribir
    if (this.subscribeCountRequest) {
      this.getCount();
    }
    this.countRequest++;
    // >>>>>>>>>>>>>>>>>>

    this.request = {
      countRequest: undefined,
      concepto: '',
      cfdi: {
        path: '',
        url: '',
      },
      fechaSol: undefined,
      user: '',
      tipo: 'REEMBOLSO',
      moneda: '',
      TC: 0,
      periodo: '',
      etapa: '',
      filmadora: {
        name: '',
        rfc: '',
      },
      aprobadoPor: '',
      valores: [],
      deducible: 'deducible',
    };

    this.arrOrders = [] as any;
    this.ordenSeleccionada = [] as any;
    this.provider = {} as any;
    this.request.moneda = this.project.monedaLocal;
    this.request.TC = this.project.tipoCambioGral;
    this.request.filmadora = this.filmadoras[0];
    this.fechaComprobante = '';
    this.indexOrdenSeleccionada = undefined;
    this.indexComprometidoSeleccionado = undefined;
    this.comprometidosSeleccionados = [] as any;
    this.comprometidoSeleccionado = {} as any;
    this.disponibleCuenta = 0;
    this.acumuladoCuenta = 0;
    this.arrXML = [] as any;
    this.xml = {} as any;
    this.proveedorSeleccionado = '';
    this.sumas.importe = 0;
    this.sumas.iva = 0;
    this.sumas.total = 0;
    this.steps.paso1 = true;
    this.steps.paso2 = false;
    this.steps.paso3 = false;
    this.steps.paso4 = false;
    this.steps.paso5 = false;
    this.numPO = undefined;
    this.filtrarperiodo();
  }

  ngOnDestroy(): void {
    this.getProviderSubscription.unsubscribe();
    this.subscribeCountRequest.unsubscribe();
    this.getCompanySubscription.unsubscribe();
    this.subscriberGetProject.unsubscribe();
    this.subscriberGetUser.unsubscribe();
    this.subscriberBudget.unsubscribe();
    // this.subscribeGetCFDI.unsubscribe();
    if (this.subscribeCountDealMemo) {
      this.subscribeCountDealMemo.unsubscribe();
    }

    if (this.subscriberGetPurchaseOrder) {
      this.subscriberGetPurchaseOrder.unsubscribe();
    }
  }
}
