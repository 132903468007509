import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BudgetService } from 'src/app/services/budget.service';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProvidersService } from 'src/app/services/providers.service';
import { PurchaseOrder } from 'src/app/models/purchaseOrder';

import Notiflix from 'notiflix-angular';
import { EmpresasService } from 'src/app/services/empresas.service';
import { GeneralService } from 'src/app/services/general.service';

declare var $: any;

@Component({
  selector: 'app-purchase-order1',
  templateUrl: './purchase-order1.component.html',
  styleUrls: ['./purchase-order1.component.css'],
})
export class PurchaseOrder1Component implements OnInit, OnDestroy {
  idCompany: string;
  idProject: string;
  po = {
    comprometidos: [] as any,
    cuentas: [] as any,
    calendario: true,
    tipo: 'Directa',
  } as PurchaseOrder;
  pago = {
    cuentas: [] as any,
  } as any;
  providers = [] as any;
  proveedorSeleccionado = '';
  fechas = {} as any;
  countDealMemo = {} as any;
  budget = [] as any;
  etapas = [] as any;
  periodos = [] as any;
  user: string;
  project = {} as any;
  acumuladoTemporal = 0;
  disponible = 0;
  importeDePartida = 0;
  activarDisponible = false;
  bitacora = [] as any;
  isEdit: any;
  arrCategoria = [] as any;
  cuentas = [] as any;
  subcuentas = [] as any;
  detalle = [] as any;
  budgetCompleto = [] as any;
  filmadoras = [] as any;
  company = [] as any;
  rfcReceptor = [] as any;
  changeFilmadora = false;
  totales = {} as any;

  // Subscriptions
  getProviderSubscription: Subscription;
  subscribeCountDealMemo: Subscription;
  subscriberBudget: Subscription;
  subscribePeriods: Subscription;
  subscriberGetUser: Subscription;
  subscriberGetProject: Subscription;
  findOrderSubscription: Subscription;
  getCompanySubscription: Subscription;

  constructor(
    private providersService: ProvidersService,
    private dealMemoService: DealMemoService,
    private budgetService: BudgetService,
    private projectService: ProjectService,
    private empresasService: EmpresasService,
    private authService: AuthService,
    private generalService: GeneralService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.isEdit = url.root.children.primary.segments[4].path;
    this.getCount();
    this.getProvider();
    this.getBudget();
    this.getPeriods();
    this.getUser();
    this.getCompany();
    this.getProject();
  }

  getCompany() {
    this.getCompanySubscription = this.empresasService
      .getCompanyActual(this.idCompany)
      .subscribe((res: any) => {
        const objFilmadora = {
          name: res.name,
          rfc: res.rfc,
        };
        this.filmadoras.push(objFilmadora);
        this.company = res;
        this.rfcReceptor = res.rfc.toUpperCase();
        this.getProject();
      });
  }

  getCount() {
    this.subscribeCountDealMemo = this.dealMemoService
      .getCountDealMemo(this.idCompany, this.idProject)
      .subscribe(res => {
        const contador: any = res;
        if (res === undefined) {
          this.countDealMemo.countDealMemo = 1;
          this.po.orderCounter = this.countDealMemo.countDealMemo;
        } else {
          this.countDealMemo.countDealMemo = contador.countDealMemo + 1;
          this.po.orderCounter = this.countDealMemo.countDealMemo;
        }
      });
  }

  getProvider() {
    this.getProviderSubscription = this.providersService
      .getProvider()
      .subscribe(res => {
        this.providers = Object.assign([], res);
      });
  }

  changeSelectProvider() {
    const segmentos = this.proveedorSeleccionado.split(' - ');
    const numeroProveedor = parseInt(segmentos[0]);

    const proveedor = this.providers.find(
      provider => provider.numero === numeroProveedor
    );

    if (proveedor !== undefined) {
      // this.datosProveedor = proveedor;
      this.po.numeroProveedor = proveedor.numero;
      this.po.nombreProveedor = proveedor.nombre;
      this.po.rfc = proveedor.rfc;
    }
  }

  getBudget() {
    this.subscriberBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          const arrBudget = this.budgetService.ctasFilter2(budget); // Esta función desglosa todo el presupuesto
          this.budgetCompleto = arrBudget; // Este es el array completo
          //console.log(budget); // este es el array que contiene hijos (children)
          let arrFilter = arrBudget.filter(
            obj => obj.CodigoAgrupador.split('-').length === 3
          );
          this.budget = arrFilter; // Este es el array que mostramos en la lista
          const arrFilterCat = arrBudget.filter(
            obj => obj.CodigoAgrupador.split('-').length === 1
          );
          this.arrCategoria = arrFilterCat;
        }
      });
  }

  selectCategoria() {
    console.log(this.pago.cuenta);
    // Buscar todas las cuentas que pertenecen a la categoria seleccionada
    console.log(this.budgetCompleto);
    this.subcuentas = this.budgetCompleto.filter(obj => {
      if (
        obj.CodigoAgrupador.split('-')[0] === this.pago.cuenta &&
        obj.CodigoAgrupador.split('-').length === 2
      ) {
        obj.CodigoAgrupador = obj.CodigoAgrupador.split('-')[1];
        return obj;
      }
    });
    console.log(this.subcuentas);
  }

  selectSubcuenta() {
    console.log(this.pago.subcuenta);
    // Buscar todas las cuentas que pertenecen a la subcuenta seleccionada
    this.detalle = this.budgetCompleto.filter(obj => {
      if (
        obj.CodigoAgrupador.split('-')[0] === this.pago.cuenta &&
        obj.CodigoAgrupador.split('-')[1] === this.pago.subcuenta &&
        obj.CodigoAgrupador.split('-').length === 3
      ) {
        // obj.CodigoAgrupador = obj.CodigoAgrupador.split('-')[2];
        return obj;
      }
    });
    console.log(this.detalle);
  }

  getPeriods() {
    this.subscribePeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        // console.log(Object.assign([], res));
        this.periodos = Object.assign([], res);
        const periods = Object.assign([], res);
        const result = periods.filter((obj, index, self) => {
          return (
            index ===
            self.findIndex(t => {
              return t.etapa === obj.etapa;
            })
          );
        });
        this.etapas = result;
      });
  }

  getUser() {
    this.subscriberGetUser = this.authService.userData$.subscribe(res => {
      this.user = res.email;
    });
  }

  getProject() {
    this.subscriberGetProject = this.empresasService
      .getProjectSpecific(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        // console.log(res);
        this.project = res;
        this.po.moneda = res.monedaLocal;
        this.po.TC = res.tipoCambioGral;

        if (res.filmadoras) {
          this.filmadoras = this.filmadoras.concat(res.filmadoras);
        }
        this.po.filmadora = this.filmadoras[0];
      });
  }

  getPago(info) {
    if (info === 'importe' || info === 'cantidad') {
      this.pago.iva = this.pago.cantidad * this.pago.importe * 0.16;
      this.pago.importeTotal = this.pago.cantidad * this.pago.importe;
      this.pago.total = this.pago.cantidad * this.pago.importe + this.pago.iva;
    } else if (info === 'iva') {
      this.pago.total = this.pago.cantidad * this.pago.importe + this.pago.iva;
    }
  }

  editOrder(obj) {
    console.log(obj);
    // console.log(this.po);
    if (obj.historico) {
      delete obj.historico;
    }

    console.log(this.po.historico);
    console.log(this.po);

    if (this.po.historico) {
      this.po.historico = [];

      this.po.historico.push(obj);
      this.dealMemoService
        .updatePO(this.idCompany, this.idProject, this.po.id, this.po)
        .then(res => {
          Notiflix.Notify.Success('Se actualizo correctamente ');
          this.generalService.updateBit(this.idCompany, this.idProject, {
            fecha: new Date(),
            mensaje: `Se Actualizo la orden #${this.po.orderCounter}`,
            user: this.user,
          });
        });
      // console.log(this.po.historico, 'se crea el historico');
    } else {
      this.po.historico = [] as any;
      this.po.historico.push(obj);
      this.dealMemoService
        .updatePO(this.idCompany, this.idProject, this.po.id, this.po)
        .then(res => {
          Notiflix.Notify.Success('Se actualizo correctamente ');
          this.generalService.updateBit(this.idCompany, this.idProject, {
            fecha: new Date(),
            mensaje: `Se Actualizo la orden #${this.po.orderCounter}`,
            user: this.user,
          });
        });
      // console.log(this.po.historico, 'cuenta con historico');
    }
  }

  selectCtaIva() {
    const objIva = this.budget.find(
      element =>
        element.CodigoAgrupador.split('-')[0] ===
          this.pago.partidaPres.split('-')[0] &&
        element.Descripcion === 'Total Fringes'
    );
    console.log(objIva);
    this.pago.partidaPresIva = objIva.CodigoAgrupador;
  }

  addPago() {
    console.log(this.pago);
    if (this.pago.partidaPres) {
      this.findOrderSubscription = this.dealMemoService
        .findOrders(this.idCompany, this.idProject, this.pago.partidaPres)
        .subscribe(res => {
          console.log(res);
          this.acumuladoTemporal = 0;
          this.disponible = 0;

          // Si encuentra orden de compra a la misma partida presupuestal
          if (res.length > 0) {
            res.forEach(elementOrder => {
              elementOrder.comprometidos.forEach(elementComprometidos => {
                if (
                  elementComprometidos.partidaPres === this.pago.partidaPres
                ) {
                  let comprometido = 0;
                  if (elementOrder.moneda === this.project.monedaLocal) {
                    comprometido =
                      elementComprometidos.importe *
                      elementComprometidos.cantidad;
                  } else {
                    comprometido =
                      (elementComprometidos.importe / elementOrder.TC) *
                      elementComprometidos.cantidad;
                  }
                  this.acumuladoTemporal += comprometido;
                }
              });
            });
            console.log(this.acumuladoTemporal);

            this.acumuladoTemporal = parseFloat(
              this.acumuladoTemporal.toFixed(3)
            );

            const obj = this.budget.find(
              element => element.CodigoAgrupador === this.pago.partidaPres
            );

            let suma = 0;
            if (this.po.calendario) {
              this.po.comprometidos.forEach(element => {
                if (this.pago.partidaPres === element.partidaPres) {
                  suma += element.cantidad * element.importe;
                }
              });
            } else {
              this.po.comprometidos.forEach(element => {
                if (this.pago.partidaPres === element.partidaPres) {
                  suma += element.importe;
                }
              });
            }

            if (this.po.moneda === this.project.monedaLocal) {
              this.disponible =
                obj.Importe_Estimado - this.acumuladoTemporal - suma;
            } else {
              this.disponible =
                obj.Importe_Estimado * this.po.TC -
                this.acumuladoTemporal -
                suma;
            }

            this.importeDePartida = obj.Importe_Estimado;

            console.log('Acumulado Temporal: ' + this.acumuladoTemporal);
            console.log('Disponible: ' + this.disponible);

            // Con Calendario
            if (this.po.calendario) {
              if (
                this.pago.partidaPres.trim().length > 0 &&
                this.pago.cantidad > 0 &&
                this.pago.importe > 0 &&
                this.fechas.fechaInicio.length > 0 &&
                this.fechas.fechaFin.length > 0
              ) {
                if (this.pago.cantidad * this.pago.importe <= this.disponible) {
                  this.pago.fechaInicio = new Date(
                    this.fechas.fechaInicio + 'T00:00:00'
                  );
                  this.pago.fechaFin = new Date(
                    this.fechas.fechaFin + 'T11:59:59'
                  );
                  this.pago.solicitudes = [];

                  // const objCuentas = {
                  //   partidaPres: this.pago.partidaPres,
                  //   importe: this.pago.importe,
                  //   cantidad: this.pago.cantidad,
                  // };
                  //

                  this.po.comprometidos.push(this.pago);
                  if (this.pago.partidaPres) {
                    const partidaPres = this.po.cuentas.find(
                      cuenta => cuenta === this.pago.partidaPres
                    );
                    if (!partidaPres) {
                      this.po.cuentas.push(this.pago.partidaPres);
                    }
                  }
                  if (this.pago.partidaPresIva) {
                    const partidaPresIva = this.po.cuentas.find(
                      cuenta => cuenta === this.pago.partidaPresIva
                    );
                    if (!partidaPresIva) {
                      this.po.cuentas.push(this.pago.partidaPresIva);
                    }
                  }
                  this.total();
                  this.pago = { cuentas: [] as any } as any;
                  this.fechas = {} as any;
                } else {
                  Notiflix.Notify.Failure(
                    'El importe excede el disponible del presupuesto'
                  );
                  Notiflix.Confirm.Show(
                    'El valor excede el disponible',
                    '¿Deseas agregarla?',
                    'Si',
                    'No',
                    () => {
                      $('#modalExcedido').modal('show');
                    },
                    () => {}
                  );
                }
              }
            } else {
              // Sin calendario
              if (
                this.pago.partidaPres.trim().length > 0 &&
                this.pago.importe > 0
              ) {
                if (this.pago.importe <= this.disponible) {
                  const objCuentas = {
                    partidaPres: this.pago.partidaPres,
                    importe: this.pago.importe,
                    cantidad: this.pago.cantidad,
                  };

                  this.po.comprometidos.push(this.pago);
                  if (this.pago.partidaPres) {
                    const partidaPres = this.po.cuentas.find(
                      cuenta => cuenta === this.pago.partidaPres
                    );
                    if (!partidaPres) {
                      this.po.cuentas.push(this.pago.partidaPres);
                    }
                  }
                  if (this.pago.partidaPresIva) {
                    const partidaPresIva = this.po.cuentas.find(
                      cuenta => cuenta === this.pago.partidaPresIva
                    );
                    if (!partidaPresIva) {
                      this.po.cuentas.push(this.pago.partidaPresIva);
                    }
                  }
                  this.total();
                  this.pago = { cuentas: [] as any } as any;
                  this.fechas = {} as any;
                } else {
                  Notiflix.Notify.Failure(
                    'El importe excede el disponible del presupuesto'
                  );
                  Notiflix.Confirm.Show(
                    'El valor excede el disponible',
                    '¿Deseas agregarla?',
                    'Si',
                    'No',
                    () => {
                      console.log('ok');
                      $('#modalExcedido').modal('show');
                    },
                    () => {}
                  );
                  this.activarDisponible = true;
                  setTimeout(() => {
                    this.activarDisponible = false;
                  }, 5000);
                }
              }
            }
          } else {
            // Si no ecuentra otras ordenes con la misma partida
            const obj = this.budget.find(
              element => element.CodigoAgrupador === this.pago.partidaPres
            );
            console.log(obj);

            this.disponible = obj.Importe_Estimado;
            if (this.po.moneda === this.project.monedaLocal) {
              this.disponible = obj.Importe_Estimado;
            } else {
              this.disponible = obj.Importe_Estimado * this.po.TC;
            }

            console.log(this.disponible);
            let sumaImportes = 0;
            if (this.po.calendario) {
              if (
                this.pago.partidaPres.trim().length > 0 &&
                this.pago.cantidad > 0 &&
                this.pago.importe > 0 &&
                this.fechas.fechaInicio.length > 0 &&
                this.fechas.fechaFin.length > 0
              ) {
                this.po.comprometidos.forEach(element => {
                  if (this.pago.partidaPres === element.partidaPres) {
                    sumaImportes += element.importe;
                  }
                });
                if (
                  this.pago.cantidad * this.pago.importe + sumaImportes <=
                  this.disponible
                ) {
                  this.pago.fechaInicio = new Date(
                    this.fechas.fechaInicio + 'T00:00:00'
                  );
                  this.pago.fechaFin = new Date(
                    this.fechas.fechaFin + 'T11:59:59'
                  );
                  const objCuentas = {
                    partidaPres: this.pago.partidaPres,
                    cantidad: this.pago.cantidad,
                    importe: this.pago.importe,
                  };
                  console.log(objCuentas);
                  this.pago.cuentas.push(objCuentas);
                  this.po.comprometidos.push(this.pago);
                  this.po.cuentas.push(this.pago.partidaPres);
                  this.total();
                  this.pago = { cuentas: [] as any } as any;
                  this.fechas = {} as any;
                } else {
                  Notiflix.Notify.Failure(
                    'El importe excede el disponible del presupuesto'
                  );
                  Notiflix.Confirm.Show(
                    'El valor excede el disponible',
                    '¿Deseas agregarla?',
                    'Si',
                    'No',
                    () => {
                      $('#modalExcedido').modal('show');
                    },
                    () => {}
                  );
                }
              }
            } else {
              if (
                this.pago.partidaPres.trim().length > 0 &&
                this.pago.importe > 0
              ) {
                this.po.comprometidos.forEach(element => {
                  if (this.pago.partidaPres === element.partidaPres) {
                    sumaImportes += element.importe;
                  }
                });
                if (this.pago.importe + sumaImportes <= this.disponible) {
                  this.pago.cantidad = 1;

                  this.po.comprometidos.push(this.pago);
                  this.po.cuentas.push(this.pago.partidaPres);
                  this.total();
                  this.pago = { cuentas: [] as any } as any;

                  this.fechas = {} as any;
                  this.pago.cantidad = 1;
                } else {
                  Notiflix.Notify.Failure(
                    'El importe excede el disponible del presupuesto'
                  );
                  Notiflix.Confirm.Show(
                    'El valor excede el disponible',
                    '¿Deseas agregarla?',
                    'Si',
                    'No',
                    () => {
                      $('#modalExcedido').modal('show');
                    },
                    () => {}
                  );
                }
              }
            }
          }
          this.findOrderSubscription.unsubscribe();
        });
    } else {
      Notiflix.Notify.Failure('Selecciona una partida presupuestal');
    }
  }

  total() {
    this.totales = {
      cantidad: 0,
      importe: 0,
      valorOC: 0,
      iva: 0,
      total: 0,
    };
    this.po.comprometidos.forEach(comprometido => {
      this.totales.cantidad += comprometido.cantidad;
      this.totales.importe += comprometido.importe;
      this.totales.valorOC += comprometido.cantidad * comprometido.importe;
      this.totales.iva += comprometido.iva;
      this.totales.total += comprometido.total;
    });
  }

  excedido() {
    if (this.po.calendario) {
      this.pago.fechaInicio = new Date(this.fechas.fechaInicio + 'T00:00:00');
      this.pago.fechaFin = new Date(this.fechas.fechaFin + 'T11:59:59');
    }
    this.pago.alerta = 'Excedido';
    this.po.comprometidos.push(this.pago);
    this.po.cuentas.push(this.pago.partidaPres);
    this.pago = { cuentas: [] as any } as any;
    this.fechas = {} as any;
    $('#modalExcedido').modal('hide');
  }

  savePo() {
    console.log(this.po);
    if (
      (this.po.actividad.trim().length > 0,
      this.po.departamento.trim().length > 0,
      this.po.aprobadoPor.trim().length > 0,
      this.po.moneda.trim().length > 0,
      this.po.comprometidos.length > 0,
      this.po.TC > 0)
    ) {
      this.po.fechaCreado = new Date();
      this.po.creadoPor = this.user;
      this.po.solicitudes = [];
      this.po.xml = [];

      this.dealMemoService
        .addPurchaseOrder(this.idCompany, this.idProject, this.po)
        .then(() => {
          Notiflix.Notify.Success('¡Guardado Exitoso!');
          this.generalService.updateBit(this.idCompany, this.idProject, {
            fecha: new Date(),
            mensaje: `Se creo la orden #${this.po.orderCounter}`,
            user: this.user,
          });
          this.dealMemoService.countDealMemo(
            this.idCompany,
            this.idProject,
            this.countDealMemo
          );
          this.po = {
            comprometidos: [] as any,
            moneda: this.project.monedaLocal,
            TC: this.project.tipoCambioGral,
            cuentas: [] as any,
            calendario: true,
          } as PurchaseOrder;
          this.proveedorSeleccionado = '';
          this.pago = {} as any;
        })
        .catch(err => {
          console.error(err);
          Notiflix.Notify.Failure(
            'Ocurrió un error al guardar la Orden de compra'
          );
          this.generalService.updateBit(this.idCompany, this.idProject, {
            fecha: new Date(),
            mensaje: `Ocurrio un error al guardar la orden # ${this.po.orderCounter}`,
            user: this.user,
          });
        });
    }
  }

  ngOnDestroy(): void {
    this.getProviderSubscription.unsubscribe();
    this.subscribeCountDealMemo.unsubscribe();
    this.subscriberBudget.unsubscribe();
    this.subscribePeriods.unsubscribe();
    this.subscriberGetUser.unsubscribe();
    this.subscriberGetProject.unsubscribe();
    this.getCompanySubscription.unsubscribe();
  }
}
